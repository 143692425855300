<template>
  <div class="app-container wrap">
    <div class="table-container">
      <form @submit.prevent="onSearch" class="filter-list">
        <div class="filter-item">
          <span class="label">退款编号:</span>
          <div class="content">
            <el-input clearable size="small" v-model="filter.refundNo"></el-input>
          </div>
        </div>
        <div class="filter-item">
          <span class="label">收货人手机号码:</span>
          <div class="content">
            <el-input clearable size="small" v-model="filter.consigneeMobile"></el-input>
          </div>
        </div>
        <div class="filter-item" v-show="filterMore">
          <span class="label">申请时间:</span>
          <div class="content">
            <el-date-picker
              class="date-picker"
              :default-time="['00:00:00', '23:59:59']"
              align="right"
              end-placeholder="结束日期"
              start-placeholder="开始日期"
              type="daterange"
              v-model="timeList"
            ></el-date-picker>
          </div>
        </div>
        <div class="filter-item" v-show="filterMore">
          <span class="label">订单编号/交易单号:</span>
          <div class="content">
            <el-input clearable size="small" v-model="filter.orderNo"></el-input>
          </div>
        </div>
        <div class="filter-item" v-show="filterMore">
          <span class="label">商品名称:</span>
          <div class="content">
            <el-input clearable size="small" v-model="filter.commodityNameLike"></el-input>
          </div>
        </div>
        <div class="filter-item" v-show="filterMore">
          <span class="label">品牌:</span>
          <div class="content">
            <el-select clearable multiple filterable size="small" style="width: 100%" v-model="filter.brandIds">
              <el-option
                  :key="idx"
                  :label="item.name"
                  :loading="brandOptionsLoading"
                  :value="item.id"
                  v-for="(item, idx) in brandList"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="filter-item" v-show="filterMore">
          <span class="label">商品规格标识:</span>
          <div class="content">
            <el-input clearable size="small" v-model="filter.skuId"></el-input>
          </div>
        </div>
        <div class="filter-item" v-show="filterMore">
          <span class="label">退款状态:</span>
          <div class="content">
            <el-select clearable size="small" style="width: 100%" v-model="filter.status">
              <el-option
                :key="idx"
                :label="item.label"
                :value="item.value"
                v-for="(item, idx) in statusOptions"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="filter-item" v-show="filterMore">
          <span class="label">退款类型:</span>
          <div class="content">
            <el-select clearable size="small" style="width: 100%" v-model="filter.type">
              <el-option
                :key="idx"
                :label="item.label"
                :value="item.value"
                v-for="(item, idx) in typeOptions"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="filter-item" v-show="filterMore">
          <span class="label">订单合同主体:</span>
          <div class="content">
            <el-select
              clearable
              size="small"
              style="width: 100%"
              v-model="filter.distributorContractInfoIds"
              multiple
              placeholder="请选择（可多选）"
            >
              <el-option
                :key="idx"
                :label="item.label"
                :loading="orderOptionsLoading"
                :value="item.value"
                v-for="(item, idx) in contractList">
              </el-option>
            </el-select>
          </div>
        </div>
        <div :class="{ 'btns-open': filterMore }" class="filter-item-btn">
          <el-button native-type="submit" size="small" type="primary">查询</el-button>
          <el-button @click="onReset" size="small">重置</el-button>
          <el-button
          :icon="filterMore ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
          @click="filterMore = !filterMore"
          class="toggle-btn"
          size="small"
          type="text"
          >{{ filterMore ? '收起' : '展开' }}</el-button
          >
          <!-- <div style="flex: 1"></div> -->
          <el-button :loading="exportLoading" @click="onExport" type="primary" size="small">导出</el-button>
        </div>
      </form>
      <table border="0" cellpadding="0" cellspacing="0" class="refund-list">
        <thead class="table-head">
          <tr class="line">
            <th>
              <div class="cell">商品信息</div>
            </th>
            <th>
              <div class="cell">单价</div>
            </th>
            <th>
              <div class="cell">数量</div>
            </th>
            <th>
              <div class="cell">收货人</div>
            </th>
            <th>
              <div class="cell">申请退款金额</div>
            </th>
            <th>
              <div class="cell">退款状态</div>
            </th>
            <th>
              <div class="cell">退款类型</div>
            </th>
            <th>
              <div class="cell">操作</div>
            </th>
          </tr>
        </thead>
        <tbody class="table-body" v-for="(item, index) in list" :key="index">
          <tr class="line tips-container">
            <td colspan="8" class="tips-content">
              <div class="cell">
                <span>{{ item.createDate | parseDefaultTime() }}</span>
                <span>
                  <span>退款编号：{{ item.refundNo }}</span>
                  <el-tag
                    size="mini"
                    hit
                    class="el-tag-copy"
                    v-clipboard:copy="item.refundNo"
                    v-clipboard:error="onError"
                    v-clipboard:success="onCopyRefundNo"
                    >复制</el-tag
                  >
                </span>
                <span>
                  <span>订单编号：{{ item.orderOnRefundVO.orderNo }}</span>
                  <el-tag
                    size="mini"
                    hit
                    class="el-tag-copy"
                    v-clipboard:copy="item.orderOnRefundVO.orderNo"
                    v-clipboard:error="onErrorOrderNo"
                    v-clipboard:success="onCopyOrderNo"
                    >复制</el-tag
                  >
                </span>
                <span>
                  <span v-if="item.paymentTransactionNo"
                    >交易单号：{{ item.paymentTransactionNo }}</span
                  >
                </span>
                <el-tag
                  size="mini"
                  hit
                  class="el-tag-copy"
                  v-if="item.paymentTransactionNo"
                  v-clipboard:copy="item.paymentTransactionNo"
                  v-clipboard:error="onErrorTransactionNo"
                  v-clipboard:success="onCopyTransactionNo"
                  >复制</el-tag
                >
                <el-tag
                  size="mini"
                  hit
                  class="el-tag-copy"
                  style="margin: 0;"
                  v-if="item.contractInfo"
                  >合同主体:{{item.contractInfo.merchantTypeName + '-' + item.contractInfo.contractInfoName}}</el-tag
                >
              </div>
            </td>
          </tr>
          <tr class="line">
            <!-- 商品信息 -->
            <td class="goods-img goods-info">
              <div
                :key="idx"
                class="cell"
                :class="idx === item.orderDetails.length - 1 ? '' : 'border-bottom'"
                v-for="(datas, idx) in item.orderDetails"
              >
                <div class="goods-info--cell">
                  <oss-image :src="datas.imgUrl" class="table-img" />
                  <div class="goods-info--cell--rightbox">
                    <div class="goods-info--cell--name">{{ datas.commodityName }}</div>
                    <div class="subhead" v-if="datas.commoditySpecValue">
                      规格:{{ datas.commoditySpecValue }}
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <!-- 单价 -->
            <td class="single">
              <div
                :key="idx"
                class="cell"
                :class="idx === item.orderDetails.length - 1 ? '' : 'border-bottom'"
                v-for="(datas, idx) in item.orderDetails"
              >
                <div class="t-box">¥{{ datas.price }}</div>
              </div>
            </td>
            <!-- 数量 -->
            <td class="single border-right">
              <div
                :key="idx"
                class="cell"
                :class="idx === item.orderDetails.length - 1 ? '' : 'border-bottom'"
                v-for="(datas, idx) in item.orderDetails"
              >
                <div class="t-box">{{ datas.quantity }}</div>
              </div>
            </td>
            <!--  收货人 -->
            <td class="goods-info border-right">
              <div class="cell">
                <div>
                  {{ item.orderConsigneeAddressVO.consignee }},{{
                    item.orderConsigneeAddressVO.mobile
                  }}
                </div>
              </div>
            </td>
            <!--  申请退款金额 -->
            <td class="border-right">
              <div class="cell">
                {{ item.applyRefundAmount }}
              </div>
            </td>
            <!--  退款状态 -->
            <td class="border-right">
              <div class="cell">
                <div>{{ item.statusName }}</div>
              </div>
            </td>
            <!--  退款类型 -->
            <td class="border-right">
              <div class="cell">
                <div>{{ item.typeName }}</div>
              </div>
            </td>
            <td class="border-right">
              <div class="cell">
                <!-- <el-button
                  @click="revoke(item.id)"
                  v-if="item.revokeShow"
                  size="small"
                  class="cell-btn cell-btn-red"
                  >撤销申请</el-button
                > -->
                <DialogReturnGoods
                  v-if="item.status === 'WAIT_BUYER_DELIVER'"
                  :refundOrderId="item.id"
                  @handleReturnGoods="handleReturnGoods"
                />

                <div>
                  <router-link
                    :to="'/syoung/order/refund/detail/' + item.id"
                    class="link linknone"
                    >订单详情</router-link
                  >
                </div>
              </div>
            </td>
          </tr>
          <div class="line-bottom-border"></div>
        </tbody>
      </table>
      <div class="empty" v-if="list.length === 0">暂无数据</div>
      <div class="pagination">
        <el-pagination
          :current-page="pageNo"
          :disabled="listLoading"
          :page-size="pageSize"
          :page-sizes="[10, 20, 30, 40]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          background
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { listMyPage, fetchStatusOptions, exportTable, buyerCancel } from '@/api/order/refund';
import { listOrderContractInfo } from '@/api/order/inquiry/list';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import cloneDeep from 'lodash/cloneDeep';
import download from '@/utils/download';
import { parseTime } from '@/utils';
import DialogReturnGoods from '@/components/views/order/DialogReturnGoods';
import { listAll as brandListAll } from '@/api/setting/commodity/brand';
export default {
  components: {
    DialogReturnGoods,
  },
  data() {
    const initFilter = {
      refundNo: '', // 退款编号
      consigneeMobile: '', // 收货人手机号
      orderNo: '', // 订单编号
      commodityNameLike: '', // 商品名称
      brandIds: [], // 品牌
      skuId: '', // 商品规格标识
      status: '', // 退款状态
      type: '', // 退款类型
      distributorContractInfoIds: [], //合同主体
    };
    return {
      statusOptions: [], // 退款状态列表
      contractList: [], // 合同主体列表
      typeOptions: [
        { label: '全部', value: '' },
        { label: '仅退款', value: 'REFUND_ONLY' },
        { label: '退款退货', value: 'REFUND_RETURN' },
      ], // 退款类型列表
      timeList: this.initDate(), // 申请时间，由申请开始时间和申请结束时间组成 默认最近3个月
      filterMore: true,
      initFilter,
      filter: cloneDeep(initFilter),
      list: [],
      listLoading: true,
      orderOptionsLoading: false,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      btnLoading: false,
      exportLoading: false,
      brandOptionsLoading: false, // 品牌列表Loading
      brandList: [], // 品牌列表
    };
  },
  computed: {
    // 过滤
    data() {
      const data = { ...this.filter };
      if (this.timeList && this.timeList.length) {
        data.createBeginDate = this.timeList[0].getTime();
        data.createEndDate = this.timeList[1].getTime();
      }
      return pickBy(data, val => !!val);
    },
  },
  mounted() {
    this.fetchBrandListAll();
    this.fetchStatusOptions();
    this.fetchData();
    this.getOrderList();
  },
  methods: {
    initDate() {
      const start = new Date(new Date(new Date().toLocaleDateString()).getTime());
      const end = new Date(
        new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1
      );
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      return [start, end];
    },
    // 退款订单状态
    fetchStatusOptions() {
      fetchStatusOptions().then(res => {
        const data = res.data.map(item => ({
          value: item.value,
          label: item.label,
        }));
        data.unshift({ label: '全部', value: '' });
        data.splice(
          data.findIndex(item => item.value === 'REFUND_PROCESSING'),
          1
        );
        this.statusOptions = data;
      });
    },
    // 复制订单号
    onCopyRefundNo(e) {
      this.$message.success('退款编号' + e.text + '复制成功');
    },
    onError(e, text = '退款编号') {
      this.$alert(text + '复制失败', '请手动复制', {
        confirmButtonText: '确定',
        callback: action => {
          this.$message({
            type: 'info',
            message: `action: ${text}`,
          });
        },
      });
    },
    onCopyOrderNo(e) {
      this.$message.success('订单号' + e.text + '复制成功');
    },
    onErrorOrderNo(e) {
      this.onError(e, '订单号');
    },
    onCopyTransactionNo(e) {
      this.$message.success('交易单号' + e.text + '复制成功');
    },
    onErrorTransactionNo(e) {
      this.onError(e, '交易单号');
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.fetchData();
    },
    sureRefresh() {
      this.fetchData();
    },
    fetchData() {
      this.listLoading = true;
      const listQuery = pick(this, ['pageNo', 'pageSize', 'data']);
      listMyPage(listQuery)
        .then(response => {
          this.list = response.data.list;
          this.total = response.data.total;
          // this.list = response.data.list.map((item) => {
          //   item.revokeShow = this.revokeShowFun(item.type, item.status, item.orderDetails);
          //   return item;
          // });
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    onExport() {
      const listQuery = this.data;
      const len = Object.keys(listQuery).length;
      if (!len || len < 1) {
        this.$message({
          message: '请输入导出条件，再进行导出！！！',
          type: 'error',
        });
        return;
      }
      this.exportLoading = true;
      exportTable(listQuery)
        .then(res => {
          download(
            res,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            `退款订单列表-${parseTime(Date.now(), '{y}-{m}-{d}-{h}:{i}:{s}')}.xlsx`
          );
        })
        .finally(() => {
          this.exportLoading = false;
        });
    },
    onSearch() {
      this.pageNo = 1;
      this.pageSize = 10;
      this.fetchData();
    },
    onReset() {
      this.pageNo = 1;
      this.pageSize = 10;
      this.filter = cloneDeep(this.initFilter);
      this.timeList = this.initDate();
      this.$nextTick(() => {
        this.fetchData();
      });
    },
    handleReturnGoods() {
      this.onSearch();
    },
    revoke(id) {
      this.$confirm('是否撤销申请?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          buyerCancel(id).then(res => {
            if (res.code === '0') {
              this.$message({
                type: 'success',
                message: '成功!',
              });
              this.onSearch();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          });
        });
    },
    // 订单合同主体列表
    getOrderList() {
      this.orderOptionsLoading = true;
      listOrderContractInfo()
      .then(rs => {
          if (rs && rs.data) {
            const res = rs.data.map(item => ({
              value: item.id,
              label: item.merchantTypeName + '-' + item.contractInfoName,
            }));
            res.push({
              value: 'EMPTY',
              label: '无主体',
            });
            this.contractList = res;
          }
        })
        .finally(() => {
          this.orderOptionsLoading = false;
        });
    },
    // 获取品牌列表
    fetchBrandListAll() {
      this.brandOptionsLoading = true;
      brandListAll().then(res => {
        this.brandList = res.data || [];
      }).finally(() => {
        this.brandOptionsLoading = false;
      });
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
@import 'src/styles/goods-table.scss';

.filter-list {
  margin-bottom: 24px;
}

.date-picker {
  width: 260px;
}
.filter-item-btn {
  justify-content: space-between;
}

.btns-open {
  padding-right: 0 !important;
  padding-left: 60px;
}

.linknone:hover {
  text-decoration: underline;
}
.cell-btn {
  margin-bottom: 10px;
  &-red {
    color: #fff;
    background-color: #ab0033;
    border: 1px solid #ab0033;
  }
}
.refund-list .table-body .line td.tips-content {
  padding: 0 12px;
}
</style>

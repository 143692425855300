<template>
  <span>
    <el-button @click="dialogShow" class="dialog-btn" size="small">退货</el-button>
    <el-dialog
      title="填写物流信息"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      width="800px"
      :append-to-body="true"
    >
      <el-form ref="ruleForm" :model="ruleForm" label-width="80px" :rules="rules" class="ruleForm">
        <el-form-item label="物流公司" prop="companyCode">
          <el-select v-model="ruleForm.companyCode" filterable size="small" clearable>
            <el-option
              :label="item.label"
              :value="item"
              v-for="item in deliveryCompanys"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="物流单号" prop="deliveryNo">
          <el-input v-model="ruleForm.deliveryNo" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="物流说明">
          <el-input
            type="textarea"
            v-model.trim="ruleForm.remarks"
            maxlength="240"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="上传凭证">
          <PictureCard :limit="3" :maxSize="2000 * 1024" v-model="ruleForm.images"></PictureCard>
          <div class="tip">最多上传3张,最大2m</div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="btnLoading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </span>
</template>
<script>
import { fetchLogisticsOptions } from '@/api/order/inquiry/list';
import { delivered } from '@/api/order/refund';
import PictureCard from '@/components/Upload/PictureCard';
export default {
  components: {
    PictureCard,
  },
  props: {
    refundOrderId: '',
  },
  data() {
    return {
      dialogFormVisible: false,
      ruleForm: {
        companyCode: '', // 物流公司编码
        deliveryNo: '', // 物流单号
        remarks: '', // 物流说明
        images: [], //凭证
      },
      rules: {
        companyCode: [{ required: true, message: '必填信息', trigger: 'change' }],
        deliveryNo: [{ required: true, message: '必填信息', trigger: 'blur' }],
      },
      deliveryCompanys: [], // 物流公司
      btnLoading: false,
    };
  },
  created() {
    this.fetchLogisticsOptions();
  },
  methods: {
    async fetchLogisticsOptions() {
      const data = await fetchLogisticsOptions({ deliveryCompany: {} });
      this.deliveryCompanys = data.data.map((item) => ({
        value: item.code,
        label: item.name,
      }));
    },
    dialogShow() {
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
      });
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const { companyCode, deliveryNo, images, remarks } = this.ruleForm;
          const data = {
            refundOrderId: this.refundOrderId,
            companyName: companyCode.label,
            companyCode: companyCode.value,
            deliveryNo,
            remarks,
            images: images.map((item) => {
              return item.url;
            }),
          };
          this.btnLoading = true;
          delivered(data)
            .then((res) => {
              if (res.code === '0') {
                this.$emit('handleReturnGoods');
                this.dialogFormVisible = false;
              }
            })
            .finally(() => {
              this.btnLoading = true;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-btn {
  width: 80px;
  color: #AB0033;
  border: 1px solid #AB0033;
}
.ruleForm {
  /deep/.el-radio-button {
    margin-right: 30px;
  }
  &__type {
    &--box {
      display: flex;
    }
    &--div {
      width: 140px;
      margin-right: 30px;
      text-align: center;
      border: 1px solid #ccc;
    }
  }
  .el-input,
  .el-select {
    width: 300px;
  }
  .el-textarea {
    width: 600px;
  }
}

.dialog-footer {
  text-align: center;
}
</style>